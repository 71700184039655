import { throttle, debounce } from 'throttle-debounce';

const init = function () {
  $sticky = $('.js-order-sticky');

  if ($sticky.length > 0) {
    var $sticky = $sticky;
    //var $headerSticky = $('.js-header-sticky');

    const updateSticky = function () {
      var offset = 0;
      var windowWidth = $( window ).outerWidth();

      if (windowWidth < 992) {
        $sticky.trigger("sticky_kit:detach");
      } else {

        // if ($headerSticky.length > 0) {
        //   offset += $headerSticky.outerHeight();
        // }

        offset += 15;
        $sticky.stick_in_parent({ offset_top: offset });
      }

      return true;
    };

    updateSticky();

    $(window).on('resize orientationchange', debounce(200, false, updateSticky));
  }
};

export default init;