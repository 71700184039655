// Отслеживаем изменения браузера, если имеется боковое меню и место для фильтров
// Перемещает блок с фильтрами к кнопке "Фильтры" на мобильных устройствах

const init = function () {

  var $sidePlace = $('.js-catalog-filter-desktop-place');
  var $adaptivePlace = $('.js-catalog-filter-mobile-place');

  if ($sidePlace.length > 0 && $adaptivePlace.length > 0) {
    var $catalogFilter = $('.js-catalog-filter');

    if ($catalogFilter.length > 0) {

      updateFilterPlace();

      $(window).on('resize orientationchange', updateFilterPlace);
    }
  }

  function updateFilterPlace() {
    if (!$sidePlace.is(':visible')) {

      if ($sidePlace.find('.js-catalog-filter').length > 0) {
        $adaptivePlace.append($catalogFilter);
      }
    } else {

      if (!$sidePlace.find('.js-catalog-filter').length > 0) {
        $sidePlace.append($catalogFilter);
      }
    }
  }

  // Кнопка показывает/скрывает фильтры на мобильных
  $(document.body).on('click', '.js-catalog-filter-mobile-toggle', function (e) {
    e.preventDefault();

    var $button = $(this);

    if ($adaptivePlace.hasClass('active')) {
      $adaptivePlace.removeClass('active');
      $button.removeClass('active');
    } else {
      $adaptivePlace.addClass('active');
      $button.addClass('active');
    }

    return true;
  });

};

export default init;