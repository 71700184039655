const preloading = {
  selector: '.js-page-preloading',
  bodyClass: 'is-page-loading',
  reserveTimeout: 10000,

  hide() {
    $(this.selector).hide(0);
    $(document.body).removeClass(this.bodyClass);
  },

  hideTransition() {

    $(this.selector)
      .fadeOut(500, function () {
        $(document.body).removeClass(this.bodyClass);
      });

  },

  runReserveHide() {
    setTimeout(() => {
      this.hide();
    }, this.reserveTimeout)
  },

  run() {
    this.hideTransition();
  }
};

export default preloading;
