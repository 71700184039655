import svg4everybody from 'svg4everybody';
import jumpJS from 'jump.js';
import select2 from 'select2';
import lazy from 'jquery-lazy';


// -------------------------------------
//   Float label input events
// -------------------------------------
let floatFieldSelector = '.rounded-form__input';

const updateFloatFieldState = function (field) {

  if (field.value > '') {
    field.classList.add('is-active');
  } else {
    field.classList.remove('is-active');
  }
};

// -------------------------------------
//   Jump to anchor links
// -------------------------------------
if (!window.jumpJS) {
  window.jumpJS = jumpJS;
}

const initJumpToAnchorListeners = function () {
  $(document.body).on('click', 'a[data-jump]', function (e) {
    e.preventDefault();

    let link = this;
    let target = link.getAttribute('href');

    jumpJS(target);
  });
};

// -------------------------------------
//   Select2 initialization
// -------------------------------------
const initSelect2 = function () {

  function formatSelect2Prefix(prefix) {

    return function (state) {
      if (!state.id) {
        return state.text;
      }

      return `${ prefix }${ state.text }`;
    };
  }

  $('.js-select2').each(function (i, select) {

    let select2Options = {
      minimumResultsForSearch: -1,
      width: 'style',
    };

    let selectionPrefix = select.dataset.templateSelectionPrefix;
    if (selectionPrefix) {
      select2Options.templateSelection = formatSelect2Prefix(selectionPrefix);
    }

    $(select).select2(select2Options);
  });
};


// -------------------------------------
//   Lazy load initialization
// -------------------------------------
const initLazyLoad = function () {
  setInterval(function () {
    $(window).trigger('scroll');
  }, 1000);

  $('.js-lazy-load').lazy({
    visibleOnly: true,
    // Для отладки
    /*
    beforeLoad: function (element) {
      //console.log('before', element);
    },
    afterLoad: function (element) {
      //console.log('after', element);
    },
    */
  });
};

// -------------------------------------
//   All pages listeners
// -------------------------------------
const initPageListeners = function () {
  initJumpToAnchorListeners();

  // LAZY LOAD FRAMES
  $(document.body).on('click', '.js-lazy-youtube', function () {
    var $embed = $(this);
    var src = $embed.attr('data-frame-src');

    if (src > '') {
      var $frame = $('<iframe>', {
        'frameborder': '0',
        'allowfullscreen': '',
        'src': src,
      });

      $embed.replaceWith($frame);
    }
  });


  $(document.body).on('click', '.js-toggle-head-search', function (e) {
    e.preventDefault();
    $('.js-header-search').toggleClass('is-active');

    if ($('.js-header-search').hasClass('is-active')) {
      $('.js-header-search-input')[0].focus();
    }
  });

// Stop closing dropdown when clicking inside menu
  $(document).on('click', '.js-dropdown-dont-close .dropdown-menu', function (e) {
    e.stopPropagation();
  });

  // Counter plus/minus input
  $(document.body).on('click', '.js-counter-minus, .js-counter-plus', function () {
    let $btn = $(this);
    let $area = $btn.closest('.js-counter');
    let $input = $area.find('.js-counter-input');
    let minValue = parseInt($input.attr('min')) || 0;
    let maxValue = parseInt($input.attr('max')) || false;

    let increment = $btn.hasClass('js-counter-minus') ? -1 : 1;

    let inputValue = parseInt($input.val());
    let value = inputValue + increment;

    value = value < minValue ? minValue : value;

    if (maxValue !== false && value > maxValue) {
      value = maxValue;
    }

    $input.val(value);

    if (value !== inputValue) {
      $input.trigger('change');
    }
  });

};


// -------------------------------------
//   Form View Control
// -------------------------------------
// Скрыть / Показать дополнительные элементы формы или контент в зависимости от выбранных полей

const initFormViewControl = function () {

  function toggleFormViewControl(control) {
    let $control = $(control);
    let group = $control.attr('data-control-group');

    if (group > '') {
      let $groupPlaces = $('.js-form-view-control[data-control-group="' + group + '"]');

      $groupPlaces.filter(':not(:checked)').each(function (k, v) {
        let $curControl = $(v);
        let curTargetSelector = $curControl.attr('data-control-target');

        if (curTargetSelector > '') {
          let $curTarget = $(curTargetSelector);

          $curTarget.addClass('d-none');
        }
      });

      $groupPlaces.filter(':checked').each(function (k, v) {
        let $curControl = $(v);
        let curTargetSelector = $curControl.attr('data-control-target');

        if (curTargetSelector > '') {
          let $curTarget = $(curTargetSelector);

          $curTarget.removeClass('d-none');
        }
      });
    }
  }

  $(document.body).on('change', '.js-form-view-control', function () {
    toggleFormViewControl(this);
  });

  // Перебираем значения, установленные по умолчанию
  let $fieldsetControls = $('.js-form-view-control');

  $fieldsetControls.each(function (k, v) {
    toggleFormViewControl(v);
  });
};


// -------------------------------------
//   Fieldset control
// -------------------------------------
// Активируем / Деактивируем набор полей в зависимости от состояния инпута
const initFieldSetControl = function () {
  function toggleFieldsetControl(control) {
    let $control = $(control);
    let targetSelector = $control.attr('data-control-target');

    if (targetSelector > '') {
      let $target = $(targetSelector);

      if ($control.is(':checked')) {
        $target.removeAttr('disabled');
      } else {
        $target.attr('disabled', 'disabled');
      }
    }
  }

  $(document.body).on('change', '.js-form-control-fieldset', function () {
    toggleFieldsetControl(this);
  });

  // Перебираем значения, установленные по умолчанию

  let $fieldsetControls = $('.js-form-control-fieldset');

  $fieldsetControls.each(function (k, v) {
    toggleFieldsetControl(v);
  });
};


// -------------------------------------
//   Good page slider
// -------------------------------------
/**
 * Функция инициализация элементов, содержащихся в карточке товара
 * Выполняется при загрузке страницы,
 * Либо после обновления элементов страницы с помощью AJAX
 */
const initGoodPage = function () {

  let $goodPageThumbs = $('.js-good-page-slider-thumbs');
  let $goodPageSlider = $('.js-good-page-slider-main');

  // Инциализация слайдера с уменьшенными изображениями в карточке товара

  if ($goodPageThumbs.length > 0) {
    if (!$goodPageThumbs.hasClass('slick-initialized')) {
      let $goodPageThumbsSlides = $goodPageThumbs.find('.js-good-page-slider-thumbs-item');
      let maxSlidesCount = 5;
      let slidesCount = $goodPageThumbsSlides.length;
      let initSlidesCount = slidesCount > maxSlidesCount ? maxSlidesCount : slidesCount;

      if (initSlidesCount > 0) {
        $goodPageThumbs.slick({
          vertical: true,
          arrows: true,
          dots: false,
          rows: false,
          infinite: false,
          verticalSwiping: true,
          slidesToScroll: 5,
          slidesToShow: initSlidesCount,
        });
      }

      $goodPageThumbs.on('click', '.js-good-page-slider-thumbs-item', function () {
        let $thumb = $(this);
        let thumbIndex = $thumb.attr('data-slick-index');

        $goodPageSlider.slick('slickGoTo', thumbIndex);
      });
    }
  }

  if ($goodPageSlider.length > 0) {
    // Инциализация главного слайдера в карточке товара
    if (!$goodPageSlider.hasClass('slick-initialized')) {
      let $goodPageSliderSlides = $goodPageSlider.find('.js-good-page-slider-main-item');

      $goodPageSlider.on(
        'beforeChange', function (event, slick, currentSlide, nextSlide) {
          setActiveToThumb(nextSlide);
        });

      $goodPageSlider.on('init', function (event, slick) {
        setActiveToThumb(slick.currentSlide);
      });

      $goodPageSlider.slick({
        arrows: true,
        rows: false,
        dots: false,
        lazyLoad: 'ondemand',
        slidesToScroll: 1,
        slidesToShow: 1,
        centerPadding: 0,
        infinite: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              dots: true,
            },
          },
        ],
      });

      $goodPageSlider.on('click', '.js-good-page-slider-fancy', function (e) {
        e.preventDefault();

        let images = [];

        $goodPageSliderSlides.find('.js-good-page-slider-fancy').each(function (k, v) {
          let imgPath = $(v).attr('href');
          images.push({
            src: imgPath,
            opts: {
              thumb: imgPath,
            },
          });
        });

        let fancyInstance = $.fancybox.open(
          images, {
            // Try to focus on the first focusable element after opening
            autoFocus: false,
            // Put focus back to active element after closing
            backFocus: false,
          });

        fancyInstance.jumpTo($goodPageSlider.slick('getSlick').currentSlide, 100);

      });

    }
  }

  function setActiveToThumb(index) {

    if ($goodPageThumbs.length > 0) {
      $goodPageThumbs.find('.js-good-page-slider-thumbs-item')
        .removeClass('active')
        .filter('[data-slick-index=' + index + ']')
        .addClass('active');

      $goodPageThumbs.slick('slickGoTo', index);
    }
  }
};

// -------------------------------------
//   BootstrapSliderRanges
// -------------------------------------

const initBootstrapSliderRanges = function () {

  $('.js-bs-slider_with-input-ranges:not(.js-is-loaded)').each(function (k, v) {
    let $slider = $(v);
    let slider_area = $slider.closest('.js-bs-slider__area');
    let slider_from = slider_area.find('.js-bs-slider__range-from');
    let slider_to = slider_area.find('.js-bs-slider__range-to');

    $slider.addClass('js-is-loaded');

    let slider_obj = new BootstrapSlider(v, {
      formatter: function (value) {

        if (value[0] !== undefined) {
          slider_from.val(value[0]);
        }
        if (value[1] !== undefined) {
          slider_to.val(value[1]);
        }

        return value[0] + ', ' + value[1];
      },
    });

    slider_from.on('change', function () {
      slider_obj.setValue([parseInt(slider_from.val()), parseInt(slider_to.val())]);
    });

    slider_to.on('change', function () {
      slider_obj.setValue([parseInt(slider_from.val()), parseInt(slider_to.val())]);
    });
  });
};


// -------------------------------------
//   Slick sliders
// -------------------------------------
const initSlickSliders = function () {

  $('.js-landing-news-carousel:not(".slick-initialized")').each(function (k, slider) {

    $(slider).slick({
      arrows: true,
      rows: false,
      dots: false,
      lazyLoad: 'ondemand',
      slidesToScroll: 3,
      slidesToShow: 3,
      centerPadding: 0,
      infinite: false,
      appendArrows: $(slider).closest('.section').find('.section-head__slick-arrows')[0] || slider,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2,
          },
        },
      ],
    });
  });

  $('.js-intro-slider:not(".slick-initialized")').each(function (k, slider) {

    $(slider).slick({
      arrows: true,
      rows: false,
      dots: true,
      fade: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      centerPadding: 0,
      infinite: true,
      appendArrows: $(slider).closest('.js-intro').find('.js-intro-slider-arrows')[0] || slider,
      appendDots: $(slider).closest('.js-intro').find('.js-intro-slider-dots')[0] || slider,
    });
  });
};

// -------------------------------------
//   Sticky Kit
// -------------------------------------
import initStickyKit from './components/sticky';

// -------------------------------------
//   Catalog Filter
// -------------------------------------
import initCatalogFilter from './components/catalog-filter';


// -------------------------------------
//   Mobile Menu
// -------------------------------------
import initMobileMenu from './components/mobile-menu';

// -------------------------------------
//   Countdown
// -------------------------------------
import initCountdown from './components/countdown';

// -------------------------------------
//   Preloader
// -------------------------------------
import preloading from './components/page-preloading';

// -------------------------------------
//   Page initialization scripts
// -------------------------------------
// Выполняется 1 раз при загрузке страницы
window.tetInitPage = function () {
  preloading.runReserveHide();

  initCatalogFilter();

  initPageListeners();

  initLazyLoad();

  initFormViewControl();

  initFieldSetControl();

  initStickyKit();

  initMobileMenu();

  $(floatFieldSelector).on('focus blur', function () {
    updateFloatFieldState(this);
  });
};

// Выполняется при загрузке страницы и при каждом обновлении элементов
window.tetInitPageElements = function () {
  svg4everybody();

  $(floatFieldSelector).each(function (i, field) {
    updateFloatFieldState(field);
  });

  initSelect2();

  initGoodPage();

  initSlickSliders();

  initBootstrapSliderRanges();

  initLazyLoad();

  initCountdown();

  preloading.run();
};

$(document).ready(function () {
  window.tetInitPage();
  window.tetInitPageElements();
});

