import $ from 'jquery';
window.jQuery = window.$ = $;

require('./bootstrap-slim');

import Slider from 'bootstrap-slider';
window.BootstrapSlider = Slider;

import slick from 'slick-carousel';
$.slick = slick;

import countdown from 'jquery-countdown';

// using es modules
import device from 'current-device';

require('sticky-kit/dist/sticky-kit');

require("@fancyapps/fancybox");

require('./app');
