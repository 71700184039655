/**
 * Все функции и события для взаимодействия с боковым мобильным меню
 */
const init = function () {

  var clickThrottleThread = null;
  let clickThrottleTimeout = 200;
  var clickFree = true;

  var $mobileMenuArea = $('.js-mobile-menu');

  const showMobileMenu = function () {
    $mobileMenu.addClass('show');
    $menuToggles.addClass('active');
    $mobileMenuOverlay.fadeIn(500);
  };

  const hideMobileMenu = function () {
    $mobileMenu.removeClass('show');
    $menuToggles.removeClass('active');
    $mobileMenuOverlay.fadeOut(500);
  };

  // /Mobile menu actions

  const setMenuHeightToScroller = function ($menuFrom, $menuTarget, heightPriority) {
    var isHeightPriority = heightPriority || false;

    var $menuScroller = $menuTarget.closest('.mobile-menu__scroller');
    var menuFromHeight = $menuFrom.height();
    var menuTargetHeight = $menuTarget.height();
    var menuHeight = Math.max(menuFromHeight, menuTargetHeight);
    var targetDepth = getMenuDepth($menuTarget);
    var fromDepth = getMenuDepth($menuFrom);

    if (targetDepth <= 0) {
      menuHeight = 0;
    } else {

      if (isHeightPriority === false) {
        menuHeight = menuTargetHeight;
      } else if (fromDepth <= 0) {
        menuHeight = 0;
      }

    }

    var height = menuHeight > 0 ? menuHeight + 'px' : '';

    $menuScroller.css('height', height);
  };

  const throttleMenuClick = function () {
    clickFree = false;
    clickThrottleThread = setTimeout(function() {
      clickFree = true;
    }, clickThrottleTimeout);
  };

  const getMenuDepth = function ($node) {
    return $node.parents('.mobile-menu-list').length;
  };

  // Mobile menu actions

  if ($mobileMenuArea.length > 0) {
    var $mobileMenuWrapper= $mobileMenuArea.find('.mobile-menu__wrapper');

    $('.js-mobile-menu-list-link').on('click', function(e) {
      var $link = $(this);
      var $linkParentLi = $link.closest('.mobile-menu-list__item');

      if ($linkParentLi.hasClass('mobile-menu-list__item_parent')) {
        e.preventDefault();

        if (!clickFree) return false;
        throttleMenuClick();

        var $childMenu = $linkParentLi.find('.mobile-menu-list_child:eq(0)');
        var $rootMenu = $link.closest('.mobile-menu-list');
        var $fromMenu = $link.closest('.mobile-menu-list');
        var depth = getMenuDepth($link);

        setMenuHeightToScroller($fromMenu, $childMenu, true);
        $childMenu.addClass('mobile-menu-list_expanded');
        $mobileMenuWrapper.css('transform', 'translateX(' + depth * -100 + '%)');
        $mobileMenuArea.animate({
          scrollTop: 0
        }, 200);

        setTimeout(function() {
          setMenuHeightToScroller($fromMenu, $childMenu);
        }, clickThrottleTimeout);
      }
    });

    $('.js-mobile-menu-list-back').on('click', function(e) {
      e.preventDefault();

      if (!clickFree) return false;
      throttleMenuClick();

      var $linkBack = $(this);
      var $linkBackMenu = $linkBack.closest('.mobile-menu-list');
      var depth = getMenuDepth($linkBack);
      var prevLevelDepth = depth - 2;
      var $targetMenu = $linkBackMenu.parents('.mobile-menu-list:eq(0)');

      $mobileMenuWrapper.css('transform', 'translateX(' + prevLevelDepth * -100 + '%)');
      $mobileMenuArea.animate({
        scrollTop: 0
      }, 200);
      setMenuHeightToScroller($linkBackMenu, $targetMenu, true);

      setTimeout(function() {
        $linkBackMenu.removeClass('mobile-menu-list_expanded');
        setMenuHeightToScroller($linkBackMenu, $targetMenu);
      }, clickThrottleTimeout);
    });


    var $mobileMenu = $('.js-mobile-menu');
    var $mobileMenuOverlay = $('.js-mobile-menu-overlay');
    var $menuToggles = $('.js-mobile-menu-toggle');

    $(document.body).on('click', '.js-mobile-menu-toggle', function() {
      if ($mobileMenu.hasClass('show')) {
        hideMobileMenu();
      } else {
        showMobileMenu();
      }
    });


    $(document.body).on('click touchend', '.js-mobile-menu-overlay', function() {
      hideMobileMenu();
    });


  }
};

export default init;