
// Инициализация таймеров с отсчетом до определенной даты
const init = function () {

  $('.js-countdown').each(function (k, item) {
    let $item = $(item);
    let date = $item.attr('data-countdown-to');

    $item.countdown(date, function(event) {

      $(this).html(event.strftime(`
        <div class="timer__line">
          <div class="timer-item">
            <div class="timer-item__value">%D</div>
            <div class="timer-item__name">дн.</div>
          </div>
          <div class="timer-item">
            <div class="timer-item__value">%H</div>
            <div class="timer-item__name">час.</div>
          </div>
          <div class="timer-item">
            <div class="timer-item__value">%M</div>
            <div class="timer-item__name">мин.</div>
          </div>
          <div class="timer-item">
            <div class="timer-item__value">%S</div>
            <div class="timer-item__name">сек.</div>
          </div>
        </div>  
      `));
    });
  });

};

export default init;
